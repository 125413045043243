import IBAConsoleLayout from "Layout/IBAConsoleLayout";
import Form from "@amzn/awsui-components-react/polaris/form";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import { useQueryBusinessEnrolment } from "../../Api/useQueryBusinessEnrolment";
import { useState } from "react";
import Input from "@amzn/awsui-components-react/polaris/input";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import BusinessEnrolmentPageResult from "./businessEnrolmentPageResult";
import { onEnterClicked } from "../../utils/keyboardEventUtils";
import Alert from "@amzn/awsui-components-react/polaris/alert";

const BusinessEnrolmentPage = () => {
  const [businessId, setBusinessId] = useState<string>("");
  const { fetchBusinessEnrolment, isFetching, error, businessEnrolmentResponse } = useQueryBusinessEnrolment();

  const handleBusinessIdChanged = ({ detail }: { detail: { value: string } }) => {
    setBusinessId(detail.value);
  };

  const handleBusinessEnrolmentButtonClicked = () => {
    const isInputEmpty = !businessId.trim();
    if (isInputEmpty) {
      return;
    }
    fetchBusinessEnrolment(businessId);
  };

  const isButtonDisabled = !businessId.trim() || isFetching;

  return (
    <IBAConsoleLayout>
      <SpaceBetween direction="vertical" size="xxl">
        <Form
          header={<Header variant="h1">Business Enrolment</Header>}
          actions={
            <Button
              variant="primary"
              loading={isFetching}
              disabled={isButtonDisabled}
              onClick={handleBusinessEnrolmentButtonClicked}
            >
              Enrol
            </Button>
          }
          errorText={error}
        >
          <p>
            <Alert type="warning"> Note: this section is currently under development.</Alert>
          </p>
          <FormField label="Business Enrolment" stretch>
            <Input
              value={businessId}
              onChange={handleBusinessIdChanged}
              onKeyUp={onEnterClicked(() => !isButtonDisabled && handleBusinessEnrolmentButtonClicked())}
              autoFocus
              placeholder="Enter Business ID"
              disabled={isFetching}
            />
          </FormField>
        </Form>

        {businessEnrolmentResponse && <BusinessEnrolmentPageResult response={businessEnrolmentResponse} />}
      </SpaceBetween>
    </IBAConsoleLayout>
  );
};

export default BusinessEnrolmentPage;
