import React from "react";
import {
  BusinessEnrolmentDetails,
  NotAuthorizedResult,
  BusinessEnrolmentResponse,
} from "../../Types/BusinessEnrolmentTypes";
import Alert from "@amzn/awsui-components-react/polaris/alert";

type AlertType = "success" | "error" | "info";

interface AlertConfig {
  type: AlertType;
  message: string;
}

const BusinessEnrolmentDetailPageResult: React.FC<{ response: BusinessEnrolmentResponse }> = ({ response }) => {
  // Type guard to check if response is NotAuthorizedResult
  const isNotAuthorizedResult = (response: BusinessEnrolmentResponse): response is NotAuthorizedResult => {
    return "authorized" in response;
  };

  // Check for unauthorized access first
  if (isNotAuthorizedResult(response)) {
    return <Alert type="error">Access denied. You are not authorized to enrol businesses into IBA.</Alert>;
  }

  // Type guard to check if response is BusinessEnrolmentDetails
  const isBusinessEnrolmentDetails = (response: BusinessEnrolmentResponse): response is BusinessEnrolmentDetails => {
    return "status" in response;
  };

  if (!response || !response.businessId) {
    return <Alert type="error">Invalid input or missing business information.</Alert>;
  }

  if (!isBusinessEnrolmentDetails(response)) {
    return <Alert type="error">Invalid response format.</Alert>;
  }

  // BusinessEnrolmentDetails response
  const getAlertConfig = (): AlertConfig => {
    switch (response.status) {
      case "ENROLLED":
        return {
          type: "success",
          message: `Business ${response.businessId} successfully enrolled.`,
        };
      case "ENROLLING":
        return {
          type: "info",
          message: `Business ${response.businessId} enrolment is in progress.`,
        };
      case "FAILED":
        return {
          type: "error",
          message: `Enrolment for Business ${response.businessId} failed.`,
        };
      default:
        return {
          type: "error",
          message: `Unknown status for Business ${response.businessId}.`,
        };
    }
  };

  const alertConfig = getAlertConfig();

  return (
    <div>
      <Alert type={alertConfig.type}>
        {alertConfig.message}
        <br />
      </Alert>
    </div>
  );
};

export default BusinessEnrolmentDetailPageResult;
