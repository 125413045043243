import { useRef, useState } from "react";
import { useAuthContext } from "./useAuthenticate";
import { useEnvVarsContext } from "./useEnvironment";

export function useWebSocketApi(route: string) {
  const { jwtToken } = useAuthContext();
  const { WEBSOCKET_ENDPOINT } = useEnvVarsContext();
  const [error, setError] = useState<string | null>(null);
  const socketRef = useRef<WebSocket | null>(null);

  const connect = ({
    onOpen,
    onMessage,
    onError,
  }: {
    onOpen: () => void;
    onMessage: (event: MessageEvent) => void;
    onError: (error: Event) => void;
  }) => {
    const url = `${WEBSOCKET_ENDPOINT}?idToken=${jwtToken}`;

    try {
      const ws = new WebSocket(url);
      socketRef.current = ws;

      ws.onopen = () => {
        setError(null);
        onOpen();
      };

      ws.onmessage = (event) => {
        onMessage(event);
      };

      ws.onerror = (err) => {
        setError("WebSocket error occurred");
        onError(err);
      };

      ws.onclose = () => {
        socketRef.current = null;
      };
    } catch (err) {
      setError("WebSocket connection failed");
    }
  };

  const sendMessage = (message: string) => {
    if (socketRef.current) {
      const msg = JSON.stringify({
        action: route,
        data: {
          inputId: message,
        },
        idToken: jwtToken,
      });
      socketRef.current.send(msg);
    }
  };

  const closeConnection = () => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }
  };

  return { connect, socket: socketRef.current, error, sendMessage, closeConnection };
}
